import React, { FC, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Option, Select } from '@adeccoux/tag-ds';
import { AppDatePicker } from '@components/app/AppDatePicker/AppDatePicker';
import { serializeFilters } from '@features/oneOnboardingAdmin/pages/AdminDashboard/adminHelpers';
import { useRegions } from '@hooks/apiHooks';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import { RootState } from '@store/rootReducer';
import {
  adminDashboardFilters,
  resetAdminDashboardFilters,
  restoreAdminDashboardFilters,
} from '@store/slices/oneOnboarding.slice';

import AdminAutocomplete from '../../../../components/admin/AdminAutocomplete/AdminAutocomplete';
import AdminCheckboxGroup from '../../../../components/admin/AdminCheckboxGroup/AdminCheckboxGroup';
import { DropDownOptions, OOConfiguration, OOSelectorDropdownModelInterface } from '../../../oneOnboarding/interfaces';
import {
  DeletionReasonFilterModel,
  FiltersVisibility,
  StatusFilterModel,
  StepFilterModel,
} from '../../pages/AdminDashboard/AdminDashboard';
import ToggleFilter from './components/ToggleFilter';
import {
  useBranchOptions,
  useCampaignOptions,
  useSiteOptions,
  useUpdateQueryStringValueWithoutNavigation,
} from './dashBoardFilterHooks';
import {
  actionTypes,
  FilterName,
  FilterType,
  headerNames,
  multipleSelectors,
  stateKeys,
  translationKeys,
} from './DashboardFiltersConfigs';
import { DashboardFilterSelect, FilterAction } from './DashboardFilterSelect';
import useUpdateAdminDashboardFilters from './dashboardFiltersHooks';

export type OptionType = {
  label?: string;
  id: string;
  name: string;
  localName?: string;
  title?: string;
  value?: string | number;
};

interface OODashboardFiltersProps {
  filters: Array<OOSelectorDropdownModelInterface>;
  dropdownOptions: DropDownOptions;
  onboardingStatusOptions: StatusFilterModel[];
  stepFiltersOptions: StepFilterModel[];
  disabledDropDowns?: { [key: string]: boolean };
  selectedConfiguration?: OOConfiguration;
  allJourneys: any[];
  allClientOrganizations: any[];
  onClientOrganizationChange: (clientOrganisationId: any) => void;
  onJourneyChange: (journeyId: any) => void;
  controlsFetching: boolean;
  filterControls: any;
  filtersVisibility: FiltersVisibility[];
  selectedValues: Record<string, any>;
  dispatch: React.Dispatch<FilterAction>;
  resetPageNumber: () => void;
  deletionReasonItems: DeletionReasonFilterModel[];
}

const storedAdminDashboardFilters = JSON.parse(localStorage.getItem('adminDashboardFilters') ?? '{}');

const showRestoreButtonInitialState =
  !isEmpty(storedAdminDashboardFilters) && !isEqual(storedAdminDashboardFilters, adminDashboardFilters);

export const DashboardFilters: FC<OODashboardFiltersProps> = ({
  filters,
  dropdownOptions,
  onboardingStatusOptions,
  stepFiltersOptions,
  disabledDropDowns,
  selectedConfiguration,
  allClientOrganizations,
  allJourneys,
  onClientOrganizationChange,
  onJourneyChange,
  controlsFetching,
  filterControls,
  filtersVisibility,
  selectedValues,
  dispatch,
  resetPageNumber,
  deletionReasonItems,
}) => {
  const [showRestoreButton, setShowRestoreButton] = useState(showRestoreButtonInitialState);

  const { adminDashboardFilters } = useSelector((state: RootState) => state.oneOnboarding);
  const storeDispatch = useDispatch();
  const tzOffset = new Date().getTimezoneOffset() * 60;
  const { data: regionOptions } = useRegions(filterControls.isActive);
  const branchesOptions = useBranchOptions(filterControls.isActive);
  const { siteOptions, sites } = useSiteOptions(filterControls.isActive);
  const campaignOptions = useCampaignOptions(filterControls.isActive);

  const { t, i18n } = useTranslation(['recruiter', 'control-items', 'candidate_recruiter']);

  const searchString = useMemo(() => serializeFilters(adminDashboardFilters), [adminDashboardFilters]);
  useUpdateQueryStringValueWithoutNavigation(searchString);
  useUpdateAdminDashboardFilters(selectedValues, adminDashboardFilters, sites, selectedConfiguration, storeDispatch);

  const journeysOptions = useMemo(() => {
    if (selectedValues.clientOrganizationId !== '') {
      return allJourneys
        ?.filter((x) => JSON.parse(x.id).clientOrganizationId === selectedValues.clientOrganizationId)
        .map((a: any) => ({
          id: a.id,
          name: a.recruiterName,
          value: a.id,
        }));
    }
    return allJourneys?.map((a: any) => ({
      id: a.id,
      name: a.recruiterName,
      value: a.id,
    }));
  }, [allJourneys, selectedValues.clientOrganizationId]);

  const localNameCheck = (option: OptionType) => {
    if (option.localName) {
      const selectedLanguage = localStorage.getItem('i18nextLng');
      if (selectedLanguage !== 'en') {
        return option.localName;
      }
      return option.name;
    }
    return option.name;
  };

  useEffect(() => {
    if (allClientOrganizations.length === 1) {
      onClientOrganizationChange(allClientOrganizations[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClientOrganizations]);

  useEffect(() => {
    if (journeysOptions?.length === 1) {
      onJourneyChange(journeysOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeysOptions]);

  const isFilterActive = useMemo(
    () =>
      Object.keys(selectedValues).some((key) => {
        const value = selectedValues[key];
        return Array.isArray(value) ? value.length > 0 : !!value;
      }),
    [selectedValues],
  );

  const isFilterVisible = (filterName: FilterName) => {
    const filter = filtersVisibility.find(({ name }) => name === filterName);
    return filter ? filter.visible : true;
  };

  const journeyValue = useMemo(() => {
    const journeyId = selectedValues['journeyId'];
    const clientOrgId = selectedValues['clientOrganizationId'];
    if (journeyId && clientOrgId) {
      return JSON.stringify({ id: journeyId, clientOrganizationId: clientOrgId });
    }
    return '';
  }, [selectedValues]);

  return (
    <Drawer open={filterControls.isActive} anchor="left" onClose={filterControls.onClose}>
      <div className="admin" style={{ height: '100%' }}>
        <div className="oo-filters-container">
          <Box display="flex" mb={4} justifyContent="space-between">
            <IconButton onClick={filterControls.onClose}>
              <CloseRoundedIcon color="primary" />
            </IconButton>
            {isFilterActive ? (
              <Button
                endIcon={<DeleteIcon />}
                size="small"
                className="oo-filter__clear-all-filters-btn"
                onClick={() => {
                  resetPageNumber();
                  storeDispatch(resetAdminDashboardFilters());
                  dispatch({ type: 'RESET_FILTERS' });
                }}
                fullWidth={false}
                style={{ padding: '0 12px' }}
              >
                {t('recruiter:GENERAL.CANDIDATES_OVERVIEW.clearAllFilters')}
              </Button>
            ) : (
              <>
                {showRestoreButton && (
                  <Button
                    endIcon={<RestoreIcon />}
                    size="small"
                    className="oo-filter__restore-filters-btn"
                    onClick={() => {
                      storeDispatch(restoreAdminDashboardFilters());
                      dispatch({ type: 'RESTORE_FILTERS' });
                      setShowRestoreButton(false);
                    }}
                    fullWidth={false}
                    style={{ padding: '0 12px' }}
                  >
                    {t('recruiter:GENERAL.CANDIDATES_OVERVIEW.restoreFilters')}
                  </Button>
                )}
              </>
            )}
          </Box>
          {isFilterVisible(FilterName.REGION1) && (
            <Box mb={4}>
              <DashboardFilterSelect
                dispatch={dispatch}
                options={regionOptions || []}
                optionKey="REGION1"
                selectedValues={selectedValues}
              />
            </Box>
          )}
          {isFilterVisible(FilterName.LOCATION) && (
            <Box mb={4}>
              <DashboardFilterSelect
                dispatch={dispatch}
                options={dropdownOptions[headerNames['LOCATION']]}
                optionKey="LOCATION"
                selectedValues={selectedValues}
                uniqueKey={dropdownOptions[headerNames['LOCATION']].map((o) => o.name).join(',')}
                disabled={!selectedValues['regions']?.length}
              />
            </Box>
          )}
          <ToggleFilter
            checked={selectedValues['importByMe']}
            label={t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.importByMe`)}
            title={t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.candidates`)}
            onChange={(checked) => dispatch({ type: 'IMPORT_BY_ME', payload: checked })}
          />
          {/* //TODO Remove filters find */}
          {!filters.find((filter: any) => filter.name === FilterName.BRANCH) && isFilterVisible(FilterName.BRANCH) && (
            <Box mb={4}>
              <DashboardFilterSelect
                dispatch={dispatch}
                options={branchesOptions}
                optionKey="BRANCH"
                selectedValues={selectedValues}
              />
            </Box>
          )}
          {isFilterVisible(FilterName.SITE_AS_LOCATION) && !isFilterVisible(FilterName.LOCATION) && (
            <Box mb={4}>
              <DashboardFilterSelect
                dispatch={dispatch}
                options={siteOptions}
                optionKey="SITE"
                selectedValues={selectedValues}
                label={t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.filterLocation`)}
              />
            </Box>
          )}
          <Divider className="oo-filter__divider" />
          <Box mb={4}>
            <DashboardFilterSelect
              dispatch={dispatch}
              valueKey="status"
              options={onboardingStatusOptions}
              optionKey="ONBOARDING_STATUS"
              translationOptionKey="control-items:GENERAL."
              selectedValues={selectedValues}
              label={t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.onboardingStatuses`)}
            />
          </Box>
          <Box mb={4}>
            <DashboardFilterSelect
              dispatch={dispatch}
              valueKey="status"
              label={t('recruiter:GENERAL.CANDIDATE_DELETION.deletionReason')}
              options={deletionReasonItems}
              optionKey="DELETED_REASON"
              translationOptionKey="control-items:GENERAL."
              selectedValues={selectedValues}
            />
          </Box>
          <Divider className="oo-filter__divider" />
          {controlsFetching && <CircularProgress />}
          <Box mb={4}>
            <DashboardFilterSelect
              dispatch={dispatch}
              label={t('recruiter:GENERAL.CANDIDATES_OVERVIEW.clientOrganizations')}
              options={allClientOrganizations}
              optionKey="CLIENT_ORGANIZATION"
              selectedValues={selectedValues}
            />
          </Box>
          {filters.find((filter: any) => filter.name === FilterName.BRANCH) && (
            <Box mb={4}>
              <DashboardFilterSelect
                dispatch={dispatch}
                options={branchesOptions || []}
                optionKey="BRANCH"
                selectedValues={selectedValues}
              />
            </Box>
          )}
          <Box mb={4}>
            <Select
              className="tag-ds "
              label={t('recruiter:GENERAL.CANDIDATES_OVERVIEW.journies')}
              name="select"
              placeholder={t('candidate_recruiter:GENERAL.CONTROLS.selectAnOption')}
              onChange={(e: any) => {
                onJourneyChange(e);
                dispatch({ type: actionTypes.JOURNEY, payload: e });
              }}
              value={journeysOptions?.map((jo) => jo.value).includes(journeyValue) ? journeyValue : ''}
              disabled={journeysOptions?.length === 0}
              filter={journeysOptions.length > 6}
            >
              {journeysOptions.length > 0 ? (
                journeysOptions.map((x: any) => <Option label={x.name} key={x.id} value={x.value} />)
              ) : (
                <Option label={t('candidate_recruiter:GENERAL.CONTROLS.noOptions')} value="0" disabled />
              )}
            </Select>
          </Box>
          {isFilterVisible(FilterName.APPLICATION) && (
            <Box mb={4}>
              <div className="tag-ds input-wrapper ">
                <label className="caption">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.application`)}</label>
                <input
                  type="text"
                  defaultValue={selectedValues.applicationId}
                  onKeyPress={(event: any) => {
                    if (event.key === 'Enter') {
                      dispatch({ type: actionTypes.APPLICATION, payload: event.target.value });
                    }
                  }}
                />
              </div>
            </Box>
          )}
          <Box mb={4}>
            <DashboardFilterSelect
              dispatch={dispatch}
              options={campaignOptions || []}
              optionKey="CAMPAIGN"
              selectedValues={selectedValues}
              label={t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.campaigns`)}
            />
          </Box>
          {filters
            .filter((i) => i.name !== FilterName.BRANCH)
            .map((filter: OOSelectorDropdownModelInterface) => {
              const translatedFilterName = i18n.exists(`GENERAL.CANDIDATES_OVERVIEW.${translationKeys[filter.name]}`, {
                ns: 'recruiter',
              })
                ? t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.${translationKeys[filter.name]}`)
                : t(`recruiter:GENERAL.CANDIDATE_DELETION.${translationKeys[filter.name]}`);
              if (filter.type === FilterType.selector) {
                if (filter.name !== FilterName.JOB_TYPE) {
                  return (
                    <div className="oo-filter" key={filter.name}>
                      <AdminAutocomplete
                        filterSelectedOptions
                        multiple={multipleSelectors.includes(filter.name)}
                        placeholder={translatedFilterName}
                        options={dropdownOptions[filter.controlHeaderName || headerNames[filter.name]] || []}
                        onChange={(e: any, val: Array<OptionType> | OptionType | null) => {
                          dispatch({ type: actionTypes[filter.name], payload: val });
                        }}
                        getOptionLabel={(option: OptionType) =>
                          option.label ? t(`control-items:${option.label}`) : localNameCheck(option)
                        }
                        disabled={disabledDropDowns?.[filter.name]}
                        value={selectedValues[stateKeys[filter.name]]}
                        getOptionSelected={(option: OptionType) =>
                          selectedValues[stateKeys[filter.name]]?.some((so: OptionType) => so.id === option.id)
                        }
                        clearValues={() => dispatch({ type: actionTypes[filter.name], payload: [] })}
                        label={translatedFilterName}
                      />
                    </div>
                  );
                } else {
                  if (
                    filters.find((filter: any) => filter.name === FilterName.JOB_TYPE) &&
                    filter.name === FilterName.JOB_TYPE
                  ) {
                    return (
                      <Box mb={4} key={filter.name}>
                        <Select
                          className="tag-ds "
                          multiple={multipleSelectors.includes(filter.name)}
                          onChange={(e) => {
                            const payload = e.map((x: string) => {
                              return dropdownOptions[filter.controlHeaderName].find((y: any) => y.id === x);
                            });

                            dispatch({ type: actionTypes[filter.name], payload: payload });
                          }}
                          label={translatedFilterName}
                          placeholder={t('candidate_recruiter:GENERAL.CONTROLS.selectAnOption')}
                          name="select"
                          value={selectedValues?.[stateKeys[filter.name]]?.map((x: any) => x.id)}
                          disabled={disabledDropDowns?.[filter.name]}
                        >
                          {dropdownOptions[filter.controlHeaderName]?.length > 0 ? (
                            dropdownOptions[filter.controlHeaderName].map((x: any) => {
                              const renderLabel = x.label ? t(`control-items:${x.label}`) : localNameCheck(x);

                              return <Option label={renderLabel} value={x.id} key={x.id} />;
                            })
                          ) : (
                            <Option label={t('candidate_recruiter:GENERAL.CONTROLS.noOptions')} value="0" disabled />
                          )}
                        </Select>
                      </Box>
                    );
                  }
                }
              } else if (filter.type === FilterType.date) {
                return (
                  <div className="oo-filter date-field" key={filter.name}>
                    <AppDatePicker
                      name={translatedFilterName}
                      label={translatedFilterName}
                      placeholder={translatedFilterName}
                      maxDate={
                        translationKeys[filter.name] === 'startDate' ||
                        translationKeys[filter.name] === 'availableFromLabel'
                          ? undefined
                          : new Date()
                      }
                      onChange={(date) => {
                        const sDate = date.target.value
                          ? moment(date.target.value).startOf('day').add(-tzOffset, 'seconds').toISOString()
                          : null;
                        dispatch({ type: actionTypes[filter.name], payload: sDate });
                      }}
                      value={selectedValues[stateKeys[filter.name]]}
                    />
                  </div>
                );
              }
              return null;
            })}
          <Box mb={4}>
            <Select
              className="tag-ds"
              label={t('recruiter:GENERAL.CANDIDATES_OVERVIEW.steps')}
              multiple
              name="select"
              placeholder={t(`candidate_recruiter:GENERAL.CONTROLS.selectAnOption`)}
              onChange={(e) => {
                const payload = e.map((x: string) => stepFiltersOptions.find((y) => y.name === x));

                dispatch({ type: 'STEPS_UPDATE', payload });
              }}
              value={selectedValues?.steps?.map((x: any) => (typeof x === 'string' ? x : x.name))}
            >
              {stepFiltersOptions.length > 0 ? (
                stepFiltersOptions.map((x, index) => (
                  <Option label={t(`control-items:GENERAL.${x.label}`)} value={x.name} key={index} />
                ))
              ) : (
                <Option label={t('candidate_recruiter:GENERAL.CONTROLS.noOptions')} value="0" disabled />
              )}
            </Select>
          </Box>
          <AdminCheckboxGroup
            label={t('recruiter:GENERAL.CANDIDATES_OVERVIEW.integrationStateStatus')}
            options={[
              { name: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.all'), value: '' },
              { name: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.error'), value: 'error' },
              { name: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.stuck'), value: 'stuck' },
            ]}
            onChange={(value) => dispatch({ type: 'INTEGRATION_STATUS_UPDATE', payload: value })}
            checked={selectedValues.withIntegrationStatus}
          />
          <Divider className="oo-filter__divider" />
          <AdminCheckboxGroup
            label={t('recruiter:GENERAL.CANDIDATES_OVERVIEW.blobExportStatus')}
            options={[
              { name: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.notExported'), value: false },
              { name: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.exported'), value: true },
            ]}
            onChange={(value) => {
              dispatch({ type: 'BLOB_EXPORT_STATUS', payload: value });
            }}
            checked={selectedValues.blobExportStatus}
          />
          {filters.find(
            (filter: any) => filter.name === FilterName.IS_STUDENT || filter.name === FilterName.IS_EUCITIZEN,
          ) ? (
            <div className="oo-filter unread-messages-checkbox">
              {/* Does not exist anymore, delete? */}

              {filters.find((filter: any) => filter.name === FilterName.IS_STUDENT) ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="checkbox"
                        onChange={(event: any) =>
                          dispatch({ type: 'IS_STUDENT_UPDATE', payload: event.target.checked })
                        }
                        defaultChecked={selectedValues.isStudent}
                        checked={selectedValues.isStudent}
                      />
                    }
                    label={t('recruiter-deprecated:CANDIDATES_OVERVIEW.isStudent')}
                  />
                </FormGroup>
              ) : null}
              {/* Does not exist anymore, delete? */}
              {filters.find((filter: any) => filter.name === FilterName.IS_EUCITIZEN) ? (
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="checkbox"
                        onChange={(event: any) =>
                          dispatch({ type: 'IS_EUCITIZEN_UPDATE', payload: event.target.checked })
                        }
                        defaultChecked={selectedValues.isEUCITIZEN}
                        checked={selectedValues.isEUCITIZEN}
                      />
                    }
                    label={t('recruiter-deprecated:CANDIDATES_OVERVIEW.isEUCITIZEN')}
                  />
                </FormGroup>
              ) : null}
            </div>
          ) : null}
          <Divider className="oo-filter__divider" />
          {filters.find((filter: any) => filter.name === FilterName.FAILED_MAILS) && (
            <div className="oo-filter unread-messages-checkbox">
              <div className="tag-ds checkbox-container">
                <input
                  checked={selectedValues.failedMails}
                  id="oo-filter__failedMails"
                  type="checkbox"
                  onChange={(e) => {
                    dispatch({ type: 'FAILED_MAILS_UPDATE', payload: e.target.checked });
                  }}
                />
                <label htmlFor="oo-filter__failedMails">{t('recruiter:GENERAL.CANDIDATES_OVERVIEW.failedMails')}</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};
